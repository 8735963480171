import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    login: false
  },
  mutations: {
    auth(state){
      state.login = true
    }
  },
  actions: {
  },
  getters: {
    checkAuth(state){
      return state.login;
    }
  },
  modules: {
  }
})
