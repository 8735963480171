<template>
  <div id="app" class="container">
    <div class="debug" v-show="debug">
      <pre>
          {{ debug }}
      </pre>
    </div>
    <div class="spinner" v-show="loader">
      <div class="spinner-border text-light" role="status">
        <span class="sr-only"></span>
      </div>
    </div>
    <div id="login" v-if="guest">
      <input type="text" class="form-control mb-3" placeholder="login" v-model="login">
      <input type="password" class="form-control" placeholder="password" v-model="password">
      <button @click="auth" class="btn btn-info">Войти</button>
    </div>
    <div id="sites" v-if="!guest">
      <div class="row" v-show="success">
        <div class="col">
          <div class="alert alert-success">
            Сохраненно
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="edit" v-show="currentView">
            <img v-if="current.url" :src="'https://inv.ngknn.ru/' + current.url" alt="" class="img-prev">
            <div class="form-group mb-3">
              <label>Название</label>
              <input type="text" v-model="current.name" class="form-control">
            </div>
            <div class="form-group mb-3">
              <label>ЦЕНА: {{ current.price }}</label>
            </div>
            <div class="form-group mb-3">
              <label>Номер</label>
              <input type="text" v-model="current.number" class="form-control">
            </div>
            <div class="form-group mb-3">
              <label>Кабинет</label>
              <input type="text" v-model="current.office" class="form-control">
            </div>
            <div class="form-group mb-3">
              <label>Ответственный</label>
              <select class="form-control" v-model="current.user">
                <option value="Дима">Дима</option>
                <option value="Олег">Олег</option>
              </select>
            </div>
            <div class="form-group mb-3">
              <label>Тип</label>
              <select class="form-control" v-model="current.type">
                <option value=""></option>
                <option value="ПК">ПК</option>
                <option value="Ноутбук">Ноутбук</option>
                <option value="Проектор">Проектор</option>
                <option value="Роутер">Роутер</option>
                <option value="Коммутатор">Коммутатор</option>
                <option value="Экран">Экран</option>
                <option value="Интерактивная доска">Интерактивная доска</option>
                <option value="Интерактивная панель">Интерактивная панель</option>
                <option value="Инфоматы">Инфоматы</option>
                <option value="Телевизоры">Телевизоры</option>
                <option value="Монитор">Монитор</option>
                <option value="МФУ">МФУ</option>
                <option value="Принтер">Принтер</option>
                <option value="Сканер">Сканер</option>
                <option value="Копир">Копир</option>
                <option value="Колонки">Колонки</option>
                <option value="Типография">Типография</option>
                <option value="Сканер">Разное</option>
              </select>
            </div>
            <div class="form-group pt-3 pb-5">
              <label>Фото</label>
              <input type="file" ref="file" @change="changeFile">
            </div>

            <div class="form-group mb-5 d-flex justify-content-around">
              <button class="btn btn-success" @click="saveEquipment">Сохранить</button>
              <button class="btn btn-danger" @click="closeEditor">Закрыть</button>
            </div>
          </div>
          <div class="body" v-show="!currentView">
            <input :type="typeFind" v-model="find" class="form-control" placeholder="Поиск...">
            <div class="form-group">
              <label class="p-3"><input type="radio" value="text" v-model="typeFind" @change="changeFindType"> Текст</label>
              <label class="p-3"><input type="radio" value="number" v-model="typeFind" @change="changeFindType"> Номер</label>
              <button class="btn btn-danger" @click="find = ''">Очистить поле</button>
            </div>
            <table class="table">
              <thead>
              <tr>
                <th>Наименование</th>
                <th>Номер</th>
                <th>Кабинет</th>
                <th>Ответственный</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="element in filterEquipment" :key="element.id" @click="viewCurrent(element)">
                <td>{{ element.name }}</td>
                <td>{{ element.number }}</td>
                <td>{{ element.office }}</td>
                <td>{{ element.user }}</td>
              </tr>
              </tbody>

            </table>
          </div>
        </div>
        </div>
      </div>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import axios from 'axios'
import { serialize } from 'object-to-formdata';
import store from './store'

export default {
  name: 'App',
  data(){
    return{
      guest: true,
      login: '',
      password: '',
      find: '',
      equipment: [],
      current: {},
      currentView: false,
      file: '',
      success: false,
      typeFind: 'text',
      debug: '',
      loader: false
    }
  },
  computed:{
    filterEquipment(){
      return this.equipment.filter(el => {
          if (this.find === '') return true;
          if (el.name && el.name.toLowerCase().indexOf(this.find.toLowerCase()) !== -1) return true;
          if (el.number && el.number.toLowerCase().indexOf(this.find.toLowerCase()) !== -1) return true;
          if (el.office && el.office.toLowerCase().indexOf(this.find.toLowerCase()) !== -1) return true;
      });
    }
  },
  methods: {
    async getEquipment(){
      let data = await axios.get('https://inv.ngknn.ru/api/equipment')
      this.equipment = data.data;
    },
    auth(){
      if (this.login.toLocaleLowerCase() == 'admin' && this.password == 'Sov921214'){
        this.guest = false;
        store.commit('auth');
        sessionStorage.setItem('auth', 'true')
      }
    },
    viewCurrent(element){
      this.current = element;
      this.currentView = true;
    },
    async saveEquipment(){
      let formData = serialize(this.current);
      formData.append('file', this.file);
      this.loader = true;
      let data = await axios.post('https://inv.ngknn.ru/api/equipment/' + this.current.id, formData ,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
      this.loader = false;
      if (data.data.status == 'success') {
        this.closeEditor();
        this.success = true;
        setTimeout( () => {
          this.success = false;
        }, 1000);
        this.debug = ''
      } else {
        this.debug = data.data;
      }
    },
    closeEditor(){
      this.current = {};
      this.currentView = false;
    },
    changeFile(){
      this.file = this.$refs.file.files[0];
    },
    changeFindType(){
      this.find = ''
    }
  },
  mounted() {
    this.getEquipment();
    if (store.getters.checkAuth || sessionStorage.getItem('auth')){
      this.guest = false;
    }
  },
  components: {
    // HelloWorld
  }
}
</script>

<style lang="less">
@import "https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta3/dist/css/bootstrap.min.css";
@font-face {
  font-family: "Roboto";
  src: url("assets/fonts/Roboto-Regular.ttf");
}
body{
  font-family: 'Roboto', sans-serif;
}

.spinner{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-prev{
  max-width: 600px;
  width: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
